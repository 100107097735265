import React from 'react';
import { graphql } from 'gatsby';
import {
  Container, Panel, Head, Sub,
} from '../../elements';
import Back from '../../components/Back';
import Content from '../../components/Content';
import AppContainer from '../../components/AppContainer';

const Terms = ({ data: { contentfulPage: page } }) => (
  <AppContainer pageTitle={page.title}>
    <Container id="content">
      <Panel>
        <Head>{page.heading}</Head>
        {page.sections.map(section => (
          <React.Fragment key={section.id}>
            <Sub>{section.heading}</Sub>
            <Content content={section.content.childContentfulRichText.html} />
          </React.Fragment>
        ))}
      </Panel>
      <Panel />
    </Container>
  </AppContainer>
);

export const termsAndConditionsPageQuery = graphql`
  query termsAndConditionsPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      heading
      sections {
        id
        title
        heading
        subHeading
        content {
          childContentfulRichText {
            html
          }
        }
        list {
          title
          listItem {
            text {
              text
            }
            icon
          }
        }

      }
    }
  }
`;

export default Terms;
